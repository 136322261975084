import React, { Component, Suspense } from 'react';
// lib
import axios from 'axios';
import queryString from 'query-string';

// hooks
import withMySnackbar from 'hooks/withMySnackbar';
import ConfirmDialog from 'hooks/ConfirmDialog';

// mui
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {
    Button, DialogActions, DialogTitle, DialogContentText, Dialog,
    DialogContent, Stack, Grid
} from '@mui/material';

// components
import ClientView from './Client';
import Modal from 'components/UI/Modal';
import IntervenantView from './Intervenant';
import ButtonBar from './ButtonBar/ButtonBar';
import InformationView from './InfoIntervention';
import Spinner from 'components/UI/Spinner/Spinner';
import Plan from 'components/Intervention/UploadFiles/Plan/Plan';
import Document from 'components/Intervention/UploadFiles/Document/Document';
import Inventaire from 'components/Intervention/UploadFiles/Inventaire/InventaireContainer';

const Photo = React.lazy(() => import('./InfoIntervention/modal/Photo/Photo'));
const Historique = React.lazy(() => import('./InfoIntervention/modal/Historique/Historique'));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class InterventionView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: true,
            loading: false,
            getValue: false,
            filesPlan: [],
            filesDocument: [],
            filesInventaire: [],
            fileDevisClient: [],
            fileDemandeInitial: [],
            resumeDemandeDeDevis: [],
            resumeFicheIntervention: [],
            dataFacturation: null,
            openAlertRequest: false,
            alertRequest: null,
            horairesOuverture: "",
            infosMagasin: "",
            fileFicheInterventionIntervenant: [],
            fileRemoveFicheInterventionIntervenant: [],
            fileDemandeDevisIntervenant: [],
            fileRemoveDemandeDevisIntervenant: [],
            update: false,
            dialogArchive: false,
            uploadInventaire: false,
            uploadDocument: false,
            uploadPlan: false,
            modalPhoto: false,
            modalHistorique: false,
            boolPhotos: false,
            confirmDialogOpen: false,
            isAllActionsClosed: [],
            isAllDevisValid: [],
            dialogFinish: false,
            currentUserControl: "",
        }
    }

    handleCloseAlertRequest = (event, reason) => {
        this.setState({ openAlertRequest: false, alertRequest: null });
    }

    doSomethingBeforeUnload = () => {
        if (this.props.infoIntervention.on_change !== "") {
            const header = {
                IDMastore: this.props.infoIntervention.id_mastore,
                Onchange: "",
            };
            axios.defaults.withCredentials = true;
            axios.post('/legacy/ChangeOnModifIntervention', header)
                .then(() => {
                    console.log("Modification terminée.");
                })
                .catch(error => {
                    console.error("Erreur lors de la mise à jour du statut on_change", error);
                });
        }
    }

    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.doSomethingBeforeUnload();
        });
        window.addEventListener('locationchange', (ev) => {
            ev.preventDefault();
            return this.doSomethingBeforeUnload();
        });
        window.addEventListener("unload", this.doSomethingBeforeUnload);
    };

    TakeTheControlOfTheIntervention = () => {
        const header = {
            IDMastore: this.props.infoIntervention.id_mastore,
            Onchange: this.props.user.userID, // L'utilisateur qui prend le contrôle
        };

        axios.defaults.withCredentials = true;
        axios.post('/legacy/ChangeOnModifIntervention', header)
            .then(() => {
                this.setState({ currentUserControl: this.props.user.userID });
                // Recharger la page pour tous les utilisateurs
                window.location.reload(); // Cela pourrait être optimisé avec une logique de polling ou websockets
            })
            .catch(error => {
                this.props.enqueueSnackbar('Erreur lors de la prise de contrôle.', { variant: 'error' });
            });
    }

    saveFicheInter = (fileFicheInterventionIntervenant, fileRemoveFicheInterventionIntervenant) => {
        let newState = { ...this.state }

        newState.fileFicheInterventionIntervenant = fileFicheInterventionIntervenant
        newState.fileRemoveFicheInterventionIntervenant = fileRemoveFicheInterventionIntervenant
        newState.update = true

        this.setState(newState)
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.doSomethingBeforeUnload);
        window.removeEventListener("unload", this.doSomethingBeforeUnload);
        this.doSomethingBeforeUnload();
    }

    componentDidMount() {
        this.setupBeforeUnloadListener();
        let newState = { ...this.state }

        let params = this.getParamUrl();
        const header = {
            id_mastore: params["IDMastore"],
        }

        axios.defaults.withCredentials = true
        axios.post('/legacy/GetInterventionByID', header)
            .then(response => {
                newState.intervenant = this.props.actions;
                newState.getValue = true;
                newState.pageLoading = false;
                if (response.data.filesPlans)
                    newState.filesPlan = response.data.filesPlans;
                if (response.data.filesDocuments)
                    newState.filesDocument = response.data.filesDocuments;
                if (response.data.filesInventaires)
                    newState.filesInventaire = response.data.filesInventaires;
                if (response.data.demandeInitial)
                    newState.fileDemandeInitial = response.data.demandeInitial;
                if (response.data.filesDevisClient)
                    newState.fileDevisClient = response.data.filesDevisClient;
                if (response.data.boolPhotos)
                    newState.boolPhotos = response.data.boolPhotos;
                if (response.data.dataFacturation)
                    newState.dataFacturation = response.data.dataFacturation;
                if (response.data.horairesOuverture)
                    newState.horairesOuverture = response.data.horairesOuverture;
                if (response.data.infosMagasin)
                    newState.infosMagasin = response.data.infosMagasin;
                if (response.data.fileFicheInterventionIntervenant)
                    newState.fileFicheInterventionIntervenant = response.data.fileFicheInterventionIntervenant;
                if (response.data.fileDemandeDevisIntervenant)
                    newState.fileDemandeDevisIntervenant = response.data.fileDemandeDevisIntervenant;
                const header = {
                    IDMastore: params.IDMastore,
                    Onchange: this.props.user.userID,
                }
                axios.defaults.withCredentials = true
                if (this.props.infoIntervention.on_change === "") {
                    axios.post('/legacy/ChangeOnModifIntervention', header);
                    newState.currentUserControl = this.props.user.userID;
                } else {
                    newState.currentUserControl = this.props.infoIntervention.on_change;
                }
                this.setState(newState);
            })
            .catch(error => {
                this.setState({
                    openAlertRequest: true, alertRequest: <Alert onClose={this.handleCloseAlertRequest} severity="error">
                        Erreur lors de la récupération des données de l'intervention! Peut-être que l'id indiqué dans l'url n'existe pas!
                    </Alert>
                });
            });
    }

    getParamUrl = () => {
        let values = queryString.parse(window.location.search);
        if (values.IDMastore) {
            return values;
        }
        return { "IDMastore": -1 };
    }

    CloseModal = () => {
        this.setState({
            modalPhoto: false,
            uploadPlan: false,
            uploadInventaire: false,
            uploadDocument: false,
            modalHistorique: false,
        });
    }

    openModalPhoto = () => {
        this.setState({ modalPhoto: true });
    }

    openModalHistorique = () => {
        this.setState({ modalHistorique: true });
    }

    changeBoolUploadPlan = () => {
        this.setState({ uploadPlan: true });
    }

    changeBoolUploadDocument = () => {
        this.setState({ uploadDocument: true });
    }

    changeBoolUploadInventaire = () => {
        this.setState({ uploadInventaire: true });
    }

    planSelectedHandler = (files) => {
        this.setState({ filesPlan: files, update: true });
    }

    documentSelectedHandler = (files) => {
        this.setState({ filesDocument: files, update: true });
    }

    createDataFile = (tabfiles) => {
        let formData = new FormData();
        tabfiles.map(file => {
            if (!file.file.preview) {
                formData.append('nameFiles', file.name);
                formData.append('zone', file.zone);
                formData.append('specialite', file.specialite);
                formData.append('objet', file.objet);
                formData.append('periode', file.periode);
                formData.append('imgBase64', file.file);
                return formData;
            } else {
                return (null);
            }
        })
        tabfiles.map(file => {
            if (file.file.preview) {
                formData.append('nameFiles', file.name);
                formData.append('zone', file.zone);
                formData.append('specialite', file.specialite);
                formData.append('objet', file.objet);
                formData.append('periode', file.periode);
                formData.append('files', file.file);
                return formData;
            } else {
                return (null);
            }
        })
        return formData;
    }

    stockDocuments = () => {
        const formData = this.createDataFile(this.state.filesDocument);
        formData.append('IDMastore', this.props.infoIntervention.id_mastore);
        formData.append('Attribut', "Documents");

        axios.defaults.withCredentials = true;
        return axios.post('/legacy/stockDocuments', formData);
    }

    stockPlans = () => {
        const formData = this.createDataFile(this.state.filesPlan);
        formData.append('NumeroMagasin', this.props.infoIntervention.numero_magasin);
        formData.append('Enseigne', this.props.infoIntervention.enseigne);
        formData.append('Attribut', "Plans");

        axios.defaults.withCredentials = true;
        return axios.post('/legacy/stockPlans', formData);
    }

    createDataFileClient = (tabfiles) => {
        let formData = new FormData();
        tabfiles.map(file => {
            if (!file.file) {
                formData.append('cout', file.Cout);
                formData.append('name', file.Name);
                formData.append('imgBase64', file.File);
                return formData;
            } else {
                return (null);
            }
        })
        tabfiles.map(file => {
            if (file.file) {
                formData.append('cout', file.Cout);
                formData.append('name', file.Name);
                formData.append('files', file.file);
                return formData;
            } else {
                return (null);
            }
        })
        return formData;
    }

    setSpinner = () => {
        let newState = { ...this.state };
        newState.loading = true;
        this.setState(newState);
    }

    removeDemandeDevisIntervenant = () => {
        let formData = new FormData();
        this.state.fileRemoveDemandeDevisIntervenant.map((item, i) => {
            formData.append("ID", item.ID);
            return formData;
        })
        axios.defaults.withCredentials = true;
        return axios.post('/legacy/removeDemandeDevisIntervenant', formData);
    }

    removeFicheInterventionIntervenant = () => {
        let formData = new FormData();
        this.state.fileRemoveFicheInterventionIntervenant.map((item, i) => {
            formData.append("ID", item.ID);
            return formData;
        })
        axios.defaults.withCredentials = true;
        return axios.post('/legacy/removeFicheInterventionIntervenant', formData);
    }

    stockFilesClient = () => {
        let formData = new FormData();
        formData = this.createDataFileClient(this.state.fileDevisClient);
        formData.append('IDMastore', this.props.infoIntervention.id_mastore);
        formData.append('Attribut', "DevisClient");
        axios.defaults.withCredentials = true;
        return axios.post('/legacy/stockFilesClient', formData);
    }

    saveIntervention = () => {
        Promise.all([
            this.setSpinner(),
            this.stockFilesClient(),
            this.removeDemandeDevisIntervenant(),
            this.removeFicheInterventionIntervenant(),
            this.stockPlans(),
            this.stockDocuments(),
            this.props.saveIntervention(),
            this.props.saveAutomatiqueCommentaire(),
        ]).then((response) => {
            window.location.reload(true);
        }).catch(() => {
            this.setState({
                openAlertRequest: true, alertRequest: <Alert onClose={this.handleCloseAlertRequest} severity="error">
                    Erreur lors de sauvegarde de la demande d'intervention !
                </Alert>, pageLoading: false
            });
        })
    }

    archiveIntervention = () => {
        this.setSpinner();
        const options = { month: 'long', weekday: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
        const header = {
            IDMastore: this.props.infoIntervention.id_mastore,
            ArchivesBy: "Archivée le " + new Date().toLocaleDateString("fr-FR", options).replace(":", "h") + " par " + this.props.user.firstname + " " + this.props.user.lastname
        }
        axios.defaults.withCredentials = true;
        axios.post('/legacy/ArchivesIntervention', header)
            .then(() => {
                window.location.reload(true);
            })
            .catch(error => {
                this.setState({
                    openAlertRequest: true, alertRequest: <Alert onClose={this.handleCloseAlertRequest} severity="error">
                        Erreur lors de l'archive de l'intervention!
                    </Alert>, pageLoading: false
                });
            });
    }

    openDialog = () => {
        this.setState({ dialogArchive: true });
    }

    closeDialog = () => {
        this.setState({ dialogArchive: false });
    }

    areIconsRed = () => {
        return (
            !this.props.isAllActionsClosed ||
            !this.props.isAllDevisValid ||
            (this.props.clientInfo.devis && this.props.clientInfo.devis.length === 0)
        );
    };

    openDialogFinish = () => {
        this.setState({ confirmDialogOpen: true });
    };

    closeDialogFinish = () => {
        this.setState({ confirmDialogOpen: false });
    };

    confirmTermination = () => {
        if (this.props.isAllActionsClosed && this.props.isAllDevisValid) {
            this.closeDialogFinish();
        }
    };

    createDemandeDevis = (response) => {
        let newState = { ...this.state }

        newState.fileDemandeDevisIntervenant = newState.fileDemandeDevisIntervenant.concat(response.data.fileDemandeDevisIntervenant[0])
        newState.modalDemandeDevisIntervenant = true
        newState.update = true

        this.setState(newState)
    }

    createFicheIntervention = (response) => {
        let newState = { ...this.state }

        newState.fileFicheInterventionIntervenant = newState.fileFicheInterventionIntervenant.concat(response.data.fileFicheInterventionIntervenant[0])
        newState.modalFicheInter = true
        newState.update = true

        this.setState(newState)
    }

    saveDemandeDevis = (fileDemandeDevisIntervenant, fileRemoveDemandeDevisIntervenant) => {
        let newState = { ...this.state }

        newState.fileDemandeDevisIntervenant = fileDemandeDevisIntervenant
        newState.fileRemoveDemandeDevisIntervenant = fileRemoveDemandeDevisIntervenant
        newState.update = true

        this.setState(newState)
    }


    render() {
        const { currentUserControl } = this.state;
        const { user } = this.props;
        let modalPageIntervention = (
            <React.Fragment>
                <Modal open={this.state.modalPhoto} close={this.CloseModal}>
                    <Suspense fallback={<Spinner />}>
                        <Photo actions={this.props.actions} modalClosed={this.CloseModal} infoIntervention={this.props.infoIntervention} />
                    </Suspense>
                </Modal>
                <Modal open={this.state.uploadPlan} close={this.CloseModal}>
                    <Plan open={this.state.uploadPlan} modalClosed={this.CloseModal} filesPlan={this.state.filesPlan} addPlan={this.planSelectedHandler} data={this.props.infoIntervention} />
                </Modal>
                <Modal open={this.state.uploadDocument} close={this.CloseModal}>
                    <Document modalClosed={this.CloseModal} filesDocument={this.state.filesDocument} addDocument={this.documentSelectedHandler} data={this.props.infoIntervention} />
                </Modal>
                <Modal open={this.state.uploadInventaire} close={this.CloseModal}>
                    <Inventaire modalClosed={this.CloseModal} filesInventaire={this.state.filesInventaire} data={this.props.infoIntervention} />
                </Modal>
                <Modal open={this.state.modalHistorique} close={this.CloseModal}>
                    <Suspense fallback={<Spinner />}>
                        <Historique modalClosed={this.CloseModal} infoIntervention={this.props.infoIntervention} />
                    </Suspense>
                </Modal>
            </React.Fragment >
        );

        let dialog = (
            <React.Fragment>
                <Dialog
                    open={this.state.dialogArchive}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.closeDialog}
                >
                    <DialogTitle>{"Voulez-vous vraiment archiver cette intervention ?"}</DialogTitle>
                    <DialogContent style={{ padding: "21px" }}>
                        <DialogContentText>
                            Archiver l'intervention déplacera cette dernière dans la base de donnée archivée.
                            Plus aucune modification sur cette intervention sera possible.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} variant="outlined" color='error' size='small'>
                            Annuler
                        </Button>
                        <Button onClick={this.archiveIntervention} variant="outlined" color='primary' size='small'>
                            Archiver
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )

        let pageIntervention = <Spinner />;

        if (this.state.getValue) {
            pageIntervention = (
                <React.Fragment>
                    {modalPageIntervention}
                    {dialog}
                    <Grid container sx={{ height: { xs: "100%", md: "95vh" }, padding: '5px', overflow: 'auto' }} spacing={{ md: 0.5, xs: 1 }}>
                        <InformationView
                            infoIntervention={this.props.infoIntervention}
                            actions={this.props.actions}
                            clientInfo={this.props.clientInfo}
                            fileDevisClient={this.state.fileDevisClient}
                            updateInfoIntervention={this.props.updateInfoIntervention}
                            horairesOuverture={this.state.horairesOuverture}
                            infosMagasin={this.state.infosMagasin}
                            user={this.props.user}
                            openInventaireModal={this.changeBoolUploadInventaire}
                            fileInventaireLength={this.state.filesInventaire.length}
                            openModalHistorique={this.openModalHistorique}
                            openModalPhoto={this.openModalPhoto}
                            openPlanModal={this.changeBoolUploadPlan}
                            openDocumentModal={this.changeBoolUploadDocument}
                            boolPhotos={this.state.boolPhotos}
                            filePlanLength={this.state.filesPlan.length}
                            fileDocumentLength={this.state.filesDocument.length}
                        />
                        <Grid item md={6} xs={12} >
                            <Stack spacing={{ md: 0.5, xs: 1 }} sx={{ height: '100%' }}>
                                <ClientView
                                    infoIntervention={this.props.infoIntervention}
                                    updateInfoIntervention={this.props.updateInfoIntervention}
                                    clientInfo={this.props.clientInfo}
                                    setClientInfo={this.props.setClientInfo}
                                    user={this.props.user}
                                    actions={this.props.actions}
                                    fileDemandeInitial={this.state.fileDemandeInitial}
                                    fileDevis={this.state.fileDevisClient}
                                    addDevis={this.devisClientSelectedHandler}
                                    dataFacturation={this.state.dataFacturation}
                                />
                                <IntervenantView
                                    infoIntervention={this.props.infoIntervention}
                                    actions={this.props.actions}
                                    updateInfoIntervention={this.props.updateInfoIntervention}
                                    user={this.props.user}
                                    setActions={this.props.setActions}
                                    newDateIntervention={this.props.newDateIntervention}
                                    modifyDateIntervention={this.props.modifyDateIntervention}
                                    deleteDateIntervention={this.props.deleteDateIntervention}
                                    newAction={this.props.newAction}
                                    modifyAction={this.props.modifyAction}
                                    deleteAction={this.props.deleteAction}
                                    demandeDevisLength={this.state.fileDemandeDevisIntervenant.length}
                                    demandeInterLength={this.state.fileFicheInterventionIntervenant.length}
                                    createFicheIntervention={this.createFicheIntervention}
                                    saveFicheInter={this.saveFicheInter}
                                    resumeFicheIntervention={this.state.resumeFicheIntervention}
                                    fileFicheInterventionIntervenant={this.state.fileFicheInterventionIntervenant}
                                    createDemandeDevis={this.createDemandeDevis}
                                    saveDemandeDevis={this.saveDemandeDevis}
                                    resumeDemandeDeDevis={this.state.resumeDemandeDeDevis}
                                    fileDemandeDevisIntervenant={this.state.fileDemandeDevisIntervenant}
                                    devis={this.state.fileDevisIntervenant}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <ButtonBar
                        infoIntervention={this.props.infoIntervention}
                        saveIntervention={this.saveIntervention}
                        archiveIntervention={this.openDialog}
                        OnchangeIntervention={this.props.infoIntervention.on_change}
                        update={this.state.update || this.props.update}
                        openDialogFinish={this.openDialogFinish}
                        user={this.props.user}
                    />
                </React.Fragment >
            );
        }

        if (this.state.pageLoading) {
            pageIntervention = <Spinner />;
        }

        return (
            <Grid container spacing={0}>
                {currentUserControl !== user.userID ? (
                    <div>
                        <div disabled={true} style={{ filter: "alpha(opacity=50)", opacity: 0.6, pointerEvents: "none" }}>
                            {pageIntervention}
                        </div>
                        {currentUserControl !== user.userID && (
                            <Button onClick={this.TakeTheControlOfTheIntervention} style={{ left: '47%', position: 'absolute', background: 'black', color: 'white', top: '50%', zIndex: '100000' }}>
                                Prendre le contrôle {currentUserControl}
                            </Button>
                        )}
                    </div>
                ) : pageIntervention}
                <Snackbar open={this.state.openAlertRequest} autoHideDuration={6000} onClose={this.handleCloseAlertRequest}>
                    {this.state.alertErrorRequest}
                </Snackbar>
                <ConfirmDialog
                    onClose={this.closeDialogFinish}
                    disableButton={this.areIconsRed()}
                    clientInfo={this.props.clientInfo}
                    onConfirm={this.confirmTermination}
                    open={this.state.confirmDialogOpen}
                    saveIntervention={this.saveIntervention}
                    isAllDevisValid={this.props.isAllDevisValid}
                    infoIntervention={this.props.infoIntervention}
                    isAllActionsClosed={this.props.isAllActionsClosed}
                    updateInfoIntervention={this.props.updateInfoIntervention}
                />
            </Grid>
        );
    }
}

export default withMySnackbar(InterventionView);
